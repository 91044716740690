import React from 'react'

function calculateHeight(i) {
  if (i === 12 || i === 0) {
    return '4px'
  }

  if (i === 6 || i === 18) {
    return '2px'
  }

  return '1px'
}
export function HourMarker({ i }) {
  return (
    <div
      key={(100 / 24) * i}
      style={{
        height: calculateHeight(i),
        width: `${100 / 24}%`,
        float: 'left',
        borderLeft: '1px solid blue',
      }}
    />
  )
}

import React from 'react'

export function MenuItem({ item }) {
  return (
    <div
      style={{
        float: 'left',
        padding: '0 5px',
        margin: '0 5px 5px',
        fontSize: '0.6em',
        color: 'white',
        backgroundColor: 'navy',
        borderRadius: '2px',
      }}
    >
      {item.data.hh_price && <span>${item.data.hh_price}</span>}{' '}
      {item.data.name}{' '}
      {item.data.original_price && (
        <span
          style={{
            textDecoration: 'line-through',
          }}
        >
          ($
          {item.data.original_price})
        </span>
      )}
    </div>
  )
}

import {} from './../helpers/HappyHourHelpers'
import { RestaurantItemView } from '../components/RestaurantItemView'
/* eslint-disable no-undef, react/prop-types */
import React from 'react'
import get from 'lodash/get'
import { Box } from '../components/Layout'
import Layout from '../layouts'
import {
  subtractMilitaryTime,
  addMilitaryTime,
  formatMilitary,
} from '../helpers/MilitaryTimeHelpers'

class MainPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      time: new Date(),
      initialTime: new Date(),
    }

    this.state.restaurants = this.calculateRestaurants()
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ time: new Date() }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  calculateRestaurants() {
    const { data } = this.props
    const { time: now } = this.state
    const nowMilitary = now.getHours() * 100 + now.getMinutes()
    const hhs = get(data, 'allAirtable.edges').map(e => e.node)
    const restaurants = []
    hhs.forEach(hh => {
      if (!hh.data.restaurant_name) {
        return
      }

      console.log({ hh, day: now.getDay() })
      if (now.getDay() === 0 && !hh.data.Su) {
        return
      }
      if (now.getDay() === 1 && !hh.data.M) {
        return
      }
      if (now.getDay() === 2 && !hh.data.T) {
        return
      }
      if (now.getDay() === 3 && !hh.data.W) {
        return
      }
      if (now.getDay() === 4 && !hh.data.Th) {
        return
      }
      if (now.getDay() === 5 && !hh.data.F) {
        return
      }
      if (now.getDay() === 6 && !hh.data.S) {
        return
      }

      const rest = restaurants.find(r => r.name === hh.data.restaurant_name[0])

      if (rest) {
        rest.hhs.push(hh)
      } else {
        restaurants.push({ name: hh.data.restaurant_name[0], hhs: [hh] })
      }
    })

    restaurants.forEach(rest => {
      let startDiff = []
      let timeLeft = null

      rest.hhs.forEach(hh => {
        if (hh.data.Start < nowMilitary && hh.data.End > nowMilitary) {
          timeLeft = parseInt(subtractMilitaryTime(hh.data.End, nowMilitary))
        } else if (hh.data.Start > nowMilitary) {
          startDiff.push(
            parseInt(subtractMilitaryTime(hh.data.Start, nowMilitary))
          )
        } else if (hh.data.Start < nowMilitary) {
          startDiff.push(
            parseInt(
              addMilitaryTime(
                subtractMilitaryTime(2400, nowMilitary),
                hh.data.Start
              )
            )
          )
        }
      })

      rest.startDiff = [...startDiff]
      rest.timeLeft = timeLeft
    })

    restaurants.sort((rest1, rest2) => {
      if (rest1.timeLeft && !rest2.timeLeft) {
        return -1
      }

      if (rest2.timeLeft && !rest1.timeLeft) {
        return 1
      }

      if (rest1.timeLeft && rest2.timeLeft) {
        return subtractMilitaryTime(rest1.timeLeft, rest2.timeLeft)
      }

      return subtractMilitaryTime(
        Math.min(...rest1.startDiff),
        Math.min(...rest2.startDiff)
      )
    })

    return restaurants
  }

  render() {
    const { data } = this.props
    const { restaurants, time: now, initialTime: initial } = this.state
    const myData = data.site.siteMetadata
    const nowMilitary = now.getHours() * 100 + now.getMinutes()
    const initialMilitary = initial.getHours() * 100 + initial.getMinutes()
    // const nowMilitary = 130

    // const nowMilitary = '853'
    return (
      <Layout>
        {/* <div>
          Current Time: {formatMilitary(nowMilitary)}:{now.getSeconds()}
        </div>
        <div>
          Initial Time: {formatMilitary(initialMilitary)}:{initial.getSeconds()}
        </div> */}
        {/* <div>{JSON.stringify(restaurants, null, 2)}</div> */}
        <div>
          <Box>
            <Box
              width={[1, 1, 1 / 2]}
              m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']}
              px={[3, 3, 0]}
            >
              <h1>{myData.title}</h1>
              <h3>{myData.description}</h3>
              {restaurants.map(rest => (
                <RestaurantItemView
                  key={rest.name}
                  rest={rest}
                  now={now}
                  nowMilitary={nowMilitary}
                />
              ))}
            </Box>
          </Box>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allAirtable(
      sort: { fields: [data___Start, data___restaurant_name], order: ASC }
    ) {
      edges {
        node {
          id
          data {
            restaurant_name
            menu_items {
              id
              data {
                name
                hh_price
                original_price
              }
            }
            Name
            Start
            End
            M
            T
            W
            Th
            F
            S
            Su
          }
        }
      }
    }
  }
`
export default MainPage

function padNumber(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

function formatMilitary(military) {
  const paddedString = padNumber(military, 4).toString()
  return paddedString.substring(0, 2) + ':' + paddedString.substring(2, 4)
}

function addMilitaryTime(start, end) {
  let s1 = padNumber(start, 4)
  let e1 = padNumber(end, 4)

  let hours = parseInt(s1.substring(0, 2)) + parseInt(e1.substring(0, 2))
  let minutes = parseInt(s1.substring(2, 4)) + parseInt(e1.substring(2, 4))
  if (minutes > 60) {
    hours += 1
    minutes -= 60
  }

  return `${padNumber(hours, 2)}${padNumber(minutes, 2)}`
}

function getMilitaryPercentage(date) {
  let s1 = padNumber(date, 4)

  let hours = parseInt(s1.substring(0, 2))
  let minutes = hours * 60 + parseInt(s1.substring(2, 4))

  return `${(minutes / 1440) * 100}%`
}

function subtractMilitaryTime(start, end) {
  let s1 = padNumber(start, 4)
  let e1 = padNumber(end, 4)

  let hours = parseInt(s1.substring(0, 2)) - parseInt(e1.substring(0, 2))
  let minutes = parseInt(s1.substring(2, 4)) - parseInt(e1.substring(2, 4))
  if (minutes < 0) {
    hours -= 1
    minutes += 60
  }

  return `${padNumber(hours, 2)}${padNumber(minutes, 2)}`
}

export {
  padNumber,
  formatMilitary,
  addMilitaryTime,
  getMilitaryPercentage,
  subtractMilitaryTime,
}

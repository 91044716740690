import React from 'react'
import { Box, Flex } from './Layout'
import { isApplicableDay } from '../helpers/HappyHourHelpers'
import { HourMarker } from './Timeline/HourMarker'
import { HappyHourMarker } from './Timeline/HappyHourMarker'
import { EndOfDayMarker } from './Timeline/EndOfDayMarker'
import { CurrentTimeMarker } from './Timeline/CurrentTimeMarker'
import { MenuItem } from './MenuItem'
import { formatMilitary } from '../helpers/MilitaryTimeHelpers'
import { runInDebugContext } from 'vm'

export function RestaurantItemView({ nowMilitary, rest, now }) {
  const items = []
  for (let i = 0; i < 24; i += 1) {
    items.push(<HourMarker i={i} key={i} />)
  }

  return (
    <Box m={[1]}>
      <div>
        {rest.name}
        {rest.timeLeft ? (
          <div style={{ float: 'right' }}>
            {formatMilitary(rest.timeLeft)} left in happy hour
          </div>
        ) : (
          <div style={{ float: 'right' }}>
            {formatMilitary(Math.min(...rest.startDiff))} till Happy Hour
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: '-8px',
          fontSize: '0.8em',
          color: 'rgb(100, 100, 100)',
        }}
      >
        {rest.hhs.map((hh, i) => [
          <span
            style={{
              paddingRight: '5px',
            }}
            key={hh.id}
          >
            {/* {hh.data.M && 'M'}
            {hh.data.T && 'T'}
            {hh.data.W && 'W'}
            {hh.data.Th && 'Th'}
            {hh.data.F && 'F'}
            {hh.data.S && 'S'}
            {hh.data.Su && 'Su'} */}{' '}
            {hh.data.Start} - {hh.data.End}
          </span>,
          <span
            style={
              i === rest.hhs.length - 1
                ? { display: 'none' }
                : { paddingRight: '5px' }
            }
          >
            &
          </span>,
        ])}
      </div>
      {/* <div
        style={{
          clear: 'both',
          overflow: 'auto',
          position: 'relative',
          height: '3px',
        }}
      >
        {rest.hhs.filter(hh => isApplicableDay(now, hh)).map(hh => (
          <HappyHourMarker key={hh.id} hh={hh} />
        ))}
      </div>
      <div style={{ clear: 'both', overflow: 'auto', position: 'relative' }}>
        {items}
        <EndOfDayMarker />
      </div>
      <div
        style={{
          clear: 'both',
          overflow: 'auto',
          position: 'relative',
          height: '3px',
        }}
      >
        <CurrentTimeMarker nowMilitary={nowMilitary} />
      </div> */}
      <div
        style={{
          clear: 'both',
          overflow: 'auto',
        }}
      >
        {rest.hhs[0].data.menu_items.map(item => (
          <MenuItem key={item.id} item={item} />
        ))}
      </div>
    </Box>
  )
}
